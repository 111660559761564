import React, { useState } from 'react'
// import axios from 'redaxios'
import { Form, Formik, Field, useField } from 'formik'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import de from 'date-fns/locale/de'
registerLocale('de', de)
setDefaultLocale('de')
import 'react-datepicker/dist/react-datepicker.css'

import { firestore } from '../firebase'
import {
  validationSchemaContract,
  texts,
  initialDataContract,
  schemaContract,
  schemaArtist,
} from './BewerbungFormDefinition'
import { SectionHeading } from './BewerbungForm'

const deAGB =
  'https://firebasestorage.googleapis.com/v0/b/imgs.kuboshow.de/o/KUBOSHOW_AGBs_2023_1.pdf?alt=media'
const enAGB =
  'https://firebasestorage.googleapis.com/v0/b/imgs.kuboshow.de/o/KUBOSHOW_GTCs_2023_1.pdf?alt=media'
// const API_PATH = 'https://ksmail.vercel.app/api/sendMailAGBsAkzeptiert.php'
export const helpMailTo =
  "mailto:wennrich@kuboshow.de?subject=Problem bei Bewerbung / Application form problems&body=Oh, da ist etwas schiefgelaufen.%0D%0AWas denn nur?%0D%0AKannst Du Dein Problem beschreiben?%0D%0A%0D%0A%0D%0AOh, something's gone wrong.%0D%0AWell, what is it?%0D%0ACan you describe your trouble?"

// eslint-disable-next-line react/prop-types
const FormikFieldText = ({ type = 'text', ...props }) => {
  const [field, meta, helpers] = useField(props)

  return (
    <div className="col-sm-10 col-md-8 mb-3">
      <input
        type={type}
        {...field}
        {...props}
        className={`form-control ${
          meta.touched && meta.error ? 'is-invalid' : ''
        }`}
        autoComplete="off"
        onChange={({ target }) => {
          helpers.setValue(target.value)
          helpers.setTouched(true)
        }}
      />
      {meta.touched && meta.error ? (
        <div className="text-danger mt-1">{meta.error}</div>
      ) : null}
    </div>
  )
}

const FormikFieldDate = ({ type = 'date', formLanguage = 'de', ...props }) => {
  const [field, meta, helpers] = useField(props)

  return (
    <div className="col-sm-10 col-md-8 mb-3 form-inline">
      <span>{schemaArtist.birthday[formLanguage].slice(0, -2) + ': '}</span>
      <DatePicker
        {...field}
        {...props}
        locale={formLanguage}
        dateFormat={formLanguage === 'de' ? 'dd.MM.yyyy' : 'MM/dd/yyyy'}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        className={`ml-2 form-control ${
          meta.touched && meta.error ? 'is-invalid' : ''
        }`}
        // autoComplete="off"
        selected={(field.value && new Date(field.value)) || null}
        onChange={(date) => {
          helpers.setValue(date)
          helpers.setTouched(true)
        }}
      />
      {meta.touched && meta.error ? (
        <div className="text-danger mt-1">{meta.error}</div>
      ) : null}
    </div>
  )
}

// eslint-disable-next-line react/prop-types
const ContractForm = ({ formLanguage, initialData }) => {
  const [isErrorShown, setIsErrorShown] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  return (
    <Formik
      initialValues={{
        ...initialDataContract,
        ...initialData,
      }}
      onSubmit={async (values) => {
        try {
          await new Promise((r) => setTimeout(r, 1500))
          console.log('Submitted-Data: ', values)
          // Save artist data
          const docRefArtist = await firestore
            .collection('artists')
            .doc(values.id)
          console.log('document reference id', docRefArtist.id)

          if (docRefArtist) {
            const {
              id,
              last_name,
              first_name,
              email,
              // consentGTC_20231,
              consentInsurance,
              consentContract,
              infoCards,
              ...rest
            } = values
            console.log('update existing artist with following fields: ', rest)
            docRefArtist.update({
              ...rest,
              // consentGTC_20231: consentGTC_20231 === true,
              consentInsurance: consentInsurance === 'true',
              consentContract: consentContract === 'true',
              infoCards: infoCards || 0,
              userAgent: window?.navigator?.userAgent ?? '',
            })
            // }
            // const mailResult = await axios({
            //   method: 'post',
            //   url: `${API_PATH}`,
            //   data: JSON.stringify({
            //     to: values.email,
            //     name:
            //       values.last_name && values.first_name
            //         ? `${values.last_name} ${values.first_name}`
            //         : '',
            //   }),
            // })
            // console.log('axios result: ', mailResult)
            // if (mailResult.data.sent === true) {
            setIsFormSubmitted(true)
            setIsErrorShown(false)
          } else {
            setIsErrorShown(true)
            const docRefFailedApp = await firestore
              .collection('failed_confirm_contract_20241')
              .doc()
            docRefFailedApp.set({
              ...values,
              userAgent: window?.navigator?.userAgent ?? '',
            })
          }
        } catch (error) {
          console.error(error, error?.message)
          setIsErrorShown(true)
          const docRefFailedApp2 = await firestore
            .collection('failed_confirm_contract_20241')
            .doc()
          docRefFailedApp2.set({
            ...values,
            userAgent: window?.navigator?.userAgent ?? '',
            errorMessage: JSON.stringify(error.message, null, 1),
          })
        }
      }}
      validationSchema={validationSchemaContract}
    >
      {({
        values,
        errors,
        touched,
        submitCount,
        isSubmitting,
        isValidating,
      }) => {
        return (
          <Form>
            {/* <div className="row">
              <div className="col-5">
                <pre>{JSON.stringify(values, null, 2)}</pre>
              </div>
              <div className="col-4">
                <pre> {JSON.stringify(isFormSubmitted, null, 2)}</pre>
                <pre> {JSON.stringify(isErrorShown, null, 2)}</pre>
                <pre> {JSON.stringify(errors, null, 2)}</pre>
              </div>
              <div className="col-3">
                <pre>{JSON.stringify(touched, null, 2)}</pre>
              </div>
            </div> */}
            <SectionHeading title={texts[formLanguage].headingTaxData} />
            <div className="form-row">
              <FormikFieldText
                name="tax_number"
                placeholder={schemaContract.tax_number[formLanguage]}
              />
            </div>
            <div className="form-row">
              <FormikFieldText
                name="tax_office"
                placeholder={schemaContract.tax_office[formLanguage]}
              />
            </div>
            <p>{schemaContract.liability[formLanguage]}</p>
            <div className="form-check">
              <Field
                id="liable1"
                className="form-check-input"
                type="radio"
                name="liability"
                value="no"
              />
              <label className="form-check-label" htmlFor="liable1">
                {texts[formLanguage].radioChoiceLiableNo}
              </label>
            </div>
            <div className="form-check">
              <Field
                id="liable2"
                className="form-check-input"
                type="radio"
                name="liability"
                value="yes_7"
              />
              <label className="form-check-label" htmlFor="liable2">
                {texts[formLanguage].radioChoiceLiableYes7}
              </label>
            </div>
            <div className="form-check mb-3">
              <Field
                id="liable3"
                className="form-check-input"
                type="radio"
                name="liability"
                value="yes_19"
              />
              <label className="form-check-label" htmlFor="liable3">
                {texts[formLanguage].radioChoiceLiableYes19}
              </label>
            </div>
            {touched.liability && errors.liability ? (
              <div className="text-danger mb-3 mt-n2">
                {texts[formLanguage].liabilityMissing}
              </div>
            ) : null}
            <div className="form-row">
              <FormikFieldDate
                name="birthday"
                formLanguage={formLanguage}
                placeholder={schemaArtist.birthday[formLanguage]}
              />
            </div>
            <SectionHeading title={texts[formLanguage].headingBankData} />
            <div className="form-row">
              <FormikFieldText
                name="account_holder"
                placeholder={schemaContract.account_holder[formLanguage]}
              />
            </div>
            <div className="form-row">
              <FormikFieldText
                name="iban"
                placeholder={schemaContract.iban[formLanguage]}
              />
            </div>
            <div className="form-row">
              <FormikFieldText
                name="bic"
                placeholder={schemaContract.bic[formLanguage]}
              />
            </div>
            <div className="form-row">
              <FormikFieldText
                name="bank_name"
                placeholder={schemaContract.bank_name[formLanguage]}
              />
            </div>
            <SectionHeading
              title={texts[formLanguage].headingConsentInsurance}
            />
            <div className="form-check">
              <Field
                id="consentInsuranceYes"
                className="form-check-input"
                type="radio"
                name="consentInsurance"
                value="true"
              />
              <label
                className="form-check-label mb-3"
                htmlFor="consentInsuranceYes"
              >
                <span>
                  <strong>
                    {
                      texts[formLanguage].radioChoiceConsentInsuranceYes.split(
                        ','
                      )[0]
                    }
                  </strong>
                  ,{' '}
                  {
                    texts[formLanguage].radioChoiceConsentInsuranceYes.split(
                      ','
                    )[1]
                  }
                  <br />
                  {texts[formLanguage].insuranceAccount}: Der Ku
                  <br />
                  IBAN: DE90432500300045005253
                  <br />
                  BIC: WELADED1HRN
                </span>
              </label>
            </div>
            <div className="form-check mb-4">
              <Field
                id="consentInsuranceNo"
                className="form-check-input"
                type="radio"
                name="consentInsurance"
                value="false"
              />
              <label className="form-check-label" htmlFor="consentInsuranceNo">
                <strong>
                  {
                    texts[formLanguage].radioChoiceConsentInsuranceNo.split(
                      ','
                    )[0]
                  }
                </strong>
                ,{' '}
                {
                  texts[formLanguage].radioChoiceConsentInsuranceNo.split(
                    ','
                  )[1]
                }
              </label>
            </div>
            {touched.consentInsurance && errors.consentInsurance ? (
              <div className="text-danger mb-4 mt-0">
                {texts[formLanguage].consentInsuranceMissing}
              </div>
            ) : null}
            <SectionHeading
              title={texts[formLanguage].headingConsentContract}
            />
            <div className="form-check">
              <Field
                id="consentContractYes"
                className="form-check-input"
                type="radio"
                name="consentContract"
                value="true"
              />
              <label
                className="form-check-label mb-2"
                htmlFor="consentContractYes"
              >
                <strong>
                  {
                    texts[formLanguage].radioChoiceConsentContractYes.split(
                      ','
                    )[0]
                  }
                </strong>
                ,{' '}
                {
                  texts[formLanguage].radioChoiceConsentContractYes.split(
                    ','
                  )[1]
                }
              </label>
            </div>
            <div className="form-check mb-4">
              <Field
                id="consentContractNo"
                className="form-check-input"
                type="radio"
                name="consentContract"
                value="false"
              />
              <label className="form-check-label" htmlFor="consentContractNo">
                <strong>
                  {
                    texts[formLanguage].radioChoiceConsentContractNo.split(
                      ','
                    )[0]
                  }
                </strong>
                ,{' '}
                {texts[formLanguage].radioChoiceConsentContractNo.split(',')[1]}
              </label>
            </div>
            {touched.consentContract && errors.consentContract ? (
              <div className="text-danger mb-4 mt-0">
                {texts[formLanguage].consentContractMissing}
              </div>
            ) : null}
            {/* <p className="mt-3 mb-1">
              <b>
                <a
                  href={formLanguage === 'de' ? deAGB : enAGB}
                  target="_blank"
                  rel="noreferrer"
                >Name="text-danger mt-1">
                {texts[formLanguage].contractGTCMissing}
              </div>
            ) : null} */}
            <SectionHeading
              title={texts[formLanguage].headingInfoCards}
            />
            <div className="col-sm-10 col-md-8 mb-3 form-inline">
              <span>{texts[formLanguage].infoCards1}</span>
              <Field
                id="infoCards"
                className="form-check-input"
                type="number"
                name="infoCards"
                // value="false"
              />
              <span>{texts[formLanguage].infoCards2}</span>
              {touched.infoCards && errors.infoCards ? (
                <div className="text-danger mt-1">{errors.infoCards}</div>
              ) : null}
            </div>
            <>
              {!isFormSubmitted && (
                <button
                  className="btn btn-secondary mt-4 mb-3"
                  type="submit"
                  disabled={isSubmitting || isValidating || isFormSubmitted}
                >
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  <span>{texts[formLanguage].submitButton}</span>
                </button>
              )}
              {isFormSubmitted && (
                <p className="text-success h4 mt-3">
                  {texts[formLanguage].submittedSuccessContract}
                </p>
              )}
              {!isFormSubmitted &&
                Object.keys(errors).length >= 1 &&
                submitCount >= 1 && (
                  <p className="text-danger">
                    {texts[formLanguage].submittedInvalid1}
                    <br />
                    {texts[formLanguage].submittedInvalid2}{' '}
                    <a href={helpMailTo}>
                      {texts[formLanguage].submittedInvalid3}.
                    </a>
                  </p>
                )}
              {isErrorShown && submitCount >= 1 && (
                <p className="text-danger">
                  {texts[formLanguage].submittedFailure1}{' '}
                  <a href={helpMailTo}>
                    {texts[formLanguage].submittedFailure2}.
                  </a>
                </p>
              )}
              <p className="my-5">
                <strong>{texts[formLanguage].dataSecurity1}</strong>
                <br />
                {texts[formLanguage].dataSecurity2}
              </p>
            </>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ContractForm
